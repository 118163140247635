import React, {useEffect, useState} from "react"
import "./App.css"

import {FrontendApi, Configuration, Session, Identity, LoginFlow, LogoutFlow} from "@ory/client"

const getBasePath = () => {
  if (window.location.hostname.includes("localhost")) {
    return "http://localhost:4000"
  } else if (window.location.hostname.includes("dev.vivacitylabs.com")) {
    return "https://auth.dev.vivacitylabs.com"
  } else if (window.location.hostname.includes("staging.vivacitylabs.com")) {
    return "https://auth.staging.vivacitylabs.com"
  } else {
    return "https://auth.vivacitylabs.com"
  }
}
const basePath = getBasePath()

const ory = new FrontendApi(
  new Configuration({
    basePath,
    baseOptions: {
      withCredentials: true,
    },
  }),
)

function App() {
  const [session, setSession] = useState<Session | undefined>()
  const [logoutFlow, setLogoutFlow] = useState<LogoutFlow | undefined>()
  const [loginFlow, setLoginFlow] = useState<LoginFlow | undefined>()
  const [protectedEndpointStatus, setProtectedEndpointStatus] = useState<number | undefined>()
  const [protectedEndpointButtonDisabled, setProtectedEndpointButtonDisabled] = useState(false)

  const fetchProtectedEndpoint = async () => {
    setProtectedEndpointButtonDisabled(true)
    try {
      const res = await fetch("https://api.test-ory.dev.vivacitylabs.com/get", {
        mode: "cors",
        credentials: "include",
      })
      setProtectedEndpointStatus(res.status)
    } catch {
      setProtectedEndpointStatus(401)
    } finally {
      setProtectedEndpointButtonDisabled(false)
    }
  }

  const getUserName = (session?: Session) => {
    if (!session) {
      return "unknown"
    }
    return session.identity.traits.email || session.identity.traits.username
  }

  const signOut = async () => {
    await fetch(logoutFlow?.logout_url!, {
      mode: "cors",
      credentials: "include",
      redirect: "manual"
    })
    window.location.replace(window.origin)
  }

  useEffect(() => {
    ory
      .toSession()
      .then(({data}) => {
        setSession(data)
        ory.createBrowserLogoutFlow().then(({data: flow}) => {
          setLogoutFlow(flow)
        })
      })
      .catch((err) =>
        ory.createBrowserLoginFlow({returnTo: window.location.origin}).then(({data: flow}) => {
          setLoginFlow(flow)
        })
      )
      .catch((err) =>
        console.log(err)
      )
  }, [])

  const googleButton = () => {
    if (!session && !loginFlow) {
      return <button className="login-with-google-btn" disabled>Checking...</button>
    }
    if (loginFlow) {
      return <>
        <form action={`${loginFlow.ui.action}&return_to=${encodeURIComponent(window.location.origin)}`}
              method={loginFlow.ui.method}>
          <button className="login-with-google-btn" type="submit" name="provider" value="google">Sign In</button>
          <input type="hidden" name="upstream_parameters.hd" value="vivacitylabs.com"/>
        </form>
      </>
    }
    return <button className="login-with-google-btn" onClick={signOut}>Sign Out</button>
  }

  return (
    <div className="App">
      <div>
        <p>
          User: {getUserName(session)}
        </p>
        <p>
          Protected API Response Code: {protectedEndpointButtonDisabled ? "fetching..." : protectedEndpointStatus || "not fetched"}
          {" "}
          <button onClick={fetchProtectedEndpoint} disabled={protectedEndpointButtonDisabled}>update</button>
        </p>
      </div>
      <div>
        {googleButton()}
      </div>
    </div>
  )
}

export default App